<template>
  <section role="region" class="our-partners-section" :class="isPartner ? 'partner-page' : ''" id="our-partners"
    ref="ourPartnersSection">
    <div class="content">
      <TheSecondHead class="content-text" chipText="Vamos juntos" titleText="Nossos parceiros"
        subtitleText="Conheça os parceiros que estão com a gente:" />
      <div class="content-sheet">
        <v-row no-gutters class="creditors"
          :style="`justify-content: ` + (creditors.length % 2 == 0 ? 'center;' : 'space-between;')">
          <div v-show="showItems" class="creditor-block" v-for="(creditor, index) in creditors"
            :key="creditor.name + index">
            <NuxtLink :to="`/credor/${creditor.alias}`">
              <v-sheet class="mb-4 creditor-logo" :class="makeFade ? 'show-item' : ''"
                :style="`transition-duration: ${(index + 1) * 0.8}s !important`">
                <ThePicture :mobilePath="`/images/partners/${creditor.alias}/m-logo.png`"
                  :desktopPath="`/images/partners/${creditor.alias}/logo.png`"
                  :mobileQuerie="isPartner ? '833' : '959px'" :desktopQuerie="isPartner ? '834px' : '960px'"
                  :altText="`Logo ${creditor.name}`" />
              </v-sheet>
            </NuxtLink>
          </div>
        </v-row>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { VSheet, VRow } from 'vuetify/components';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { usePartnersStore } from '@/stores/home/partners';

const props = defineProps(['partnerName', "isPartner"])

const store = usePartnersStore();
const creditors = store.getPartners.filter(partner => partner.name != props.partnerName);

const makeFade = ref<Boolean>(false);

const ourPartnersSection = ref<any>(null)

const ourPartnersWatcher = () => {
  var section = ourPartnersSection.value;

  if (window.scrollY >= (section.offsetHeight + 200)) {
    setTimeout(() => {
      makeFade.value = true;
    }, 100)
    window.removeEventListener('scroll', ourPartnersWatcher);
  }

}

const showItems = ref<Boolean>(false);
onMounted(() => {
  showItems.value = true;
  window.addEventListener('scroll', ourPartnersWatcher, {passive: true});
  ourPartnersWatcher();
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', ourPartnersWatcher);
})
</script>

<style lang="scss">
@import "@/styles/variables";

.our-partners-section .content {
  &>* {
    -webkit-transition-duration: 0s !important;
    -moz-transition-duration: 0s !important;
    -o-transition-duration: 0s !important;
    transition-duration: 0s !important;
  }

  background-color: $greyLight;
  padding: 42px 20px 24px;

  .content-text {
    text-align: center;

    .text-chip {
      height: 32px;
    }

    h2 {
      margin: 16px 0px;
    }

    h4 {
      margin-bottom: 16px;
      max-width: 275px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .content-sheet {
    max-width: 350px;
    margin: 0 auto;

    .creditors {
      column-gap: 15px;

      .creditor-block {
        display: flex;
        display: -webkit-flex;
        justify-content: center;
        -webkit-transition-property: top;
        -moz-transition-property: top;
        -o-transition-property: top;
        transition-property: top;


        .creditor-logo {
          width: 152.38px;
          height: 68px;
          border-radius: 10px;
          border: 1px solid #DADADA;
          display: flex;
          display: -webkit-flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 4px 4px 0px $shadowColor !important;
          opacity: 0;
          -webkit-transform: translateY(44px);
          transform: translateY(44px);
          column-gap: 15.62px;

          &:hover {
            border: 1px solid $blue;
            box-shadow: 0px 6px 15px rgba(51, 3, 255, 0.20) !important;
          }

          picture {
            display: flex;
            display: -webkit-flex;
          }
        }
      }
    }
  }

  .show-item {
    opacity: 1 !important;
    -webkit-transform: translateY(0px) !important;
    transform: translateY(0px) !important;
  }

  @media screen and (min-width: 834px) and (max-width: 959px) {
    .content-sheet {
      max-width: 596px;

      .creditors {
        column-gap: 20px;

        .creditor-block .creditor-logo {
          width: 185px;
          height: 88px;
        }
      }
    }
  }

  @media screen and (min-width: 960px) and (max-width: 1199px) {
    .content-sheet {
      max-width: 870px;

      .creditor-block:nth-child(3n+1) {
        justify-content: flex-start;
      }

      .creditor-block:nth-child(3n+3) {
        justify-content: flex-end;
      }
    }
  }

  @media screen and (min-width: 834px) {
    .content-text {
      h2 {
        font-size: 40px;
      }

      h4 {
        margin-bottom: 24px;
        max-width: none;
      }
    }

    .content-sheet {
      .creditor-block:nth-child(3n+1) {
        justify-content: flex-start;
      }

      .creditor-block:nth-child(3n+3) {
        justify-content: flex-end;
      }
    }
  }

  @media screen and (min-width: 960px) {
    width: 898px;
    border-radius: 25px;
    padding: 68px 14px;
    margin: 39px auto 37px;

    .content-text {
      h2 {
        font-size: 40px;
      }

      h4 {
        margin-bottom: 24px;
      }
    }

    .content-sheet .creditors {
      column-gap: 30px;

      .creditor-block .creditor-logo {
        width: 270px;
        height: 120px;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    width: 1030px;
    border-radius: 25px;
    margin: 66px auto 0px;

    .content-sheet {
      max-width: 870px;
    }
  }
}
</style>